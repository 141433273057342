import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import "./components/assets/fonts/aeonik-font.css";
import "./components/assets/css/AboutSection.css";
import "./components/assets/css/AdminPage.css";
import "./components/assets/css/CalculatorSection.css";
import "./components/assets/css/CarouselSection.css";
import "./components/assets/css/CompaniesPage.css";
import "./components/assets/css/Dashboard.css";
import "./components/assets/css/DiscountsSection.css";
import "./components/assets/css/FAQsPage.css";
import "./components/assets/css/Footer.css";
import "./components/assets/css/ForgotPassword.css";
import "./components/assets/css/HomePage.css";
import "./components/assets/css/HowToUseSection.css";
import "./components/assets/css/Loading.css";
import "./components/assets/css/Login.css";
import "./components/assets/css/Navbar.css";
import "./components/assets/css/PartnersSection.css";
import "./components/assets/css/ReferralSearchSection.css";
import "./components/assets/css/Register.css";
import "./components/assets/css/ResetPassword.css";
import "./components/assets/css/ShopsSection.css";
import "./components/assets/css/SubscriberSection.css";
import "./components/assets/css/SuccessPage.css";
import "./components/assets/css/ValidateEmail.css";
import "./components/assets/css/WhoWeAre.css";
import "./components/assets/css/MoneyBackSection.css";
import "./components/assets/css/partnerhero.css";
import "./components/assets/css/InfluencerSection.css";
import "./components/assets/css/BlogSection.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
